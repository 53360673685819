<!--
 * @Description: 费用明细页面
 * @Author: ChenXueLin
 * @Date: 2021-10-14 16:45:18
 * @LastEditTime: 2022-04-07 17:36:04
 * @LastEditors: ChenXueLin
-->
<template>
  <div :element-loading-background="loadingBackground" v-loading="loading">
    <el-table
      :data="tableData"
      border
      highlight-current-row
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="60">
        <template slot-scope="scope">
          <el-button type="text" @click="showDetail(scope.row)">详情</el-button>
          <!-- <e6-td-operate
            :data="getOperateList(scope.row)"
            @command="handleOperate($event, scope.row)"
          ></e6-td-operate> -->
        </template>
      </el-table-column>
    </el-table>
    <fee-detail-dialog
      :curRow="curRow"
      :dialogLoading="dialogLoading"
      :feeDetailData="feeDetailData"
      :feeDetailVisible="feeDetailVisible"
      @handleCloseScene="handleCloseScene"
      :feeDetailTotalAmount="feeDetailTotalAmount"
    ></fee-detail-dialog>
  </div>
</template>

<script>
import FeeDetailDialog from "./feeDetailDialog.vue";
import { getTaskFee, getListByTaskItem } from "@/api";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
export default {
  name: "feeDetailTable",
  components: { FeeDetailDialog },
  data() {
    return {
      loading: false,
      dialogLoading: false,
      columnData: [
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipFee",
          display: true,
          fieldLabel: "设备费（元）",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceFee",
          display: true,
          fieldLabel: "服务费（元）",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "reducedPrice",
          display: true,
          fieldLabel: "优惠",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyName",
          display: true,
          fieldLabel: "币种",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taxGiveTypeName",
          display: true,
          fieldLabel: "收款方式",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "totalAmount",
          display: true,
          fieldLabel: "合计",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //费用明细表头
      tableData: [], // 表格数据
      totalAmount: "", //费用总金额
      /*****费用弹框******/
      feeDetailVisible: false,
      curRow: {}, //当前行信息
      feeDetailData: [], //明细弹框表数据
      feeDetailTotalAmount: 0 //明细弹框表总计
    };
  },
  props: ["show", "taskId"],
  computed: {},
  mixins: [base],
  watch: {
    show(val) {
      if (val) {
        this.getTaskFee();
      }
    }
  },
  methods: {
    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        if (index === 6) {
          sums[index] = this.totalAmount;
        }
      });
      return sums;
    },
    //点击详情
    showDetail(row) {
      this.feeDetailVisible = true;
      this.curRow = row;
      this.getListByTaskItemReq();
    },
    //获取任务项费用明细列表
    async getTaskFee() {
      try {
        this.loading = true;
        let res = await getTaskFee({
          taskId: this.taskId
        });
        this.tableData = res.data.detailList;
        this.totalAmount = res.data.totalAmount;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取弹框明细列表
    async getListByTaskItemReq() {
      try {
        this.dialogLoading = true;
        let res = await getListByTaskItem({
          taskItemId: this.curRow.taskItemId
        });
        let data = res.data.feeList.length ? res.data.feeList[0] : {};
        this.feeDetailData = data.feeInfoVOList;
        this.feeDetailTotalAmount = data.totalAmount;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    handleCloseScene() {
      this.feeDetailVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
