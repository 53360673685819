<!--
 * @Description: 任务单修改基本信息
 * @Author: LiangYiNing
 * @Date: 2022-02-22 09:34:55
 * @LastEditTime: 2023-03-09 17:50:18
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="修改基础信息"
    :visible.sync="visible"
    width="600px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-customer-dialog"
  >
    <el-form
      ref="baseInfoForm"
      label-width="120px"
      :rules="rules"
      :model="baseInfoForm"
    >
      <el-form-item label="客户联系人" prop="corpContactName">
        <el-input
          placeholder="客户联系人"
          v-model="baseInfoForm.corpContactName"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户联系电话" prop="corpContactPhone">
        <el-input
          placeholder="客户联系电话"
          v-model="baseInfoForm.corpContactPhone"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="实施联系人"
        prop="linkmanrpid"
        v-if="taskSource != 3 && taskSource != 5"
      >
        <div class="select-content">
          <e6-vr-select
            v-model="baseInfoForm.linkmanrpid"
            :data="contactList"
            placeholder="实施联系人"
            title="实施联系人"
            :props="{
              id: 'contactId',
              label: 'contactName'
            }"
            clearable
            @change="handleContact"
            @clear="handleClear"
          ></e6-vr-select>
          <el-button type="text" style="margin-left: 10px; " @click="addConcat">
            添加实施联系人
          </el-button>
        </div>
      </el-form-item>
      <el-form-item
        label="实施联系人"
        prop="contactName"
        v-else
        :rules="{
          required: true,
          message: '请输入实施联系人姓名',
          trigger: ['blur', 'change']
        }"
      >
        <div class="select-content">
          <el-input
            placeholder="实施联系人"
            v-model="baseInfoForm.contactName"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="实施联系电话" prop="contactPhone">
        <el-input
          placeholder="实施联系电话"
          v-model="baseInfoForm.contactPhone"
        ></el-input>
      </el-form-item>
      <el-form-item label="省市区地址" prop="defaultCity">
        <e6-vr-select
          style="width:300px"
          v-model="baseInfoForm.defaultCity"
          :data="addressList"
          placeholder="联系地址"
          title="联系地址"
          virtual
          clearable
          :is-title="true"
          remote
          @filterChange="handleLoadAddressFilter"
          @change="handleCitySelect"
          :props="{
            id: 'fullName',
            label: 'fullName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item prop="insAddress" label="详细地址">
        <el-input
          placeholder="详细地址"
          v-model="baseInfoForm.insAddress"
        ></el-input>
      </el-form-item>
      <el-form-item label="期望上门时间" prop="expectTime">
        <el-date-picker
          v-model="baseInfoForm.expectTime"
          type="datetime"
          placeholder="选择日期"
          value-format="timestamp"
          popper-class="special"
          :picker-options="pickerOptions1"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="实施原因" prop="remark">
        <el-input
          v-model="baseInfoForm.remark"
          placeholder="请输入实施原因"
          type="textarea"
          :rows="3"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>

    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      @handleClose="handleCloseAddconcat"
      @updateList="updateList"
      :isNeedReq="true"
      :corpId="baseInfoForm.corpId"
      :corpName="baseInfoForm.corpName"
    ></add-concat-dialog>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  updateTaskDetailBasicInfo,
  getContactList,
  getAddressData,
  findDownList
} from "@/api";
import { printError } from "@/utils/util";
import AddConcatDialog from "@/components/addConcatDialog.vue";
export default {
  name: "EditBaseInfoDailog",
  mixins: [base],
  components: { AddConcatDialog },
  data() {
    return {
      addressList: [],
      visible: false,
      loading: false,
      addContactVisible: false,
      contactList: [],
      newTaskLevelEnum: [],
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      baseInfoForm: {
        taskLevelCode: "", // 任务级别码
        corpId: "", //客户ID
        corpName: "", //客户名称
        taskId: "", //工单ID
        linkmanrpid: "", //联系人ID
        contactName: "", //联系人名称
        contactPhone: "", //联系人电话
        province: "", // 省ID
        provinceName: "",
        city: "", // 市ID
        cityName: "",
        insCounty: "", // 区ID
        insCountyName: "",
        street: "", // 街道ID
        streetName: "",
        defaultCity: "", //组合省市区街道
        insAddress: "", // 详细地址
        expectTime: "", // 期望上门时间
        remark: "", // 备注
        corpContactName: "",
        corpContactPhone: ""
      }
    };
  },
  props: {
    taskId: {
      type: [Number, String]
    },
    // 是否为任务单
    task: {
      type: Boolean
    },
    //工单类型7为咨询回复单
    serviceTypeCode: {
      type: [Number, String]
    },
    //工单来源3为G7单
    taskSource: {
      type: [Number, String]
    }
  },
  computed: {
    rules() {
      return {
        linkmanrpid:
          this.serviceTypeCode == 7
            ? []
            : [
                {
                  required: true,
                  message: "请选择联系人",
                  trigger: ["blur", "change"]
                }
              ],
        contactPhone:
          this.serviceTypeCode == 7
            ? []
            : [
                {
                  required: true,
                  message: "请输入联系人电话",
                  trigger: ["blur", "change"]
                }
              ],
        defaultCity: [
          {
            required: true,
            message: "请选择省市区街道",
            trigger: ["blur", "change"]
          }
        ],
        insAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"]
          }
        ],
        expectTime: [
          {
            required: true,
            message: "选择期望上门时间",
            trigger: ["blur", "change"]
          }
        ]
      };
    }
  },
  created() {
    if (this.task) {
      this.initData();
      return;
    }
  },
  methods: {
    init(data = {}) {
      this.visible = true;
      if (!this.task) {
        delete this.baseInfoForm.taskLevelCode;
        // return;
      }
      for (let key in this.baseInfoForm) {
        switch (key) {
          case "linkmanrpid":
            this.baseInfoForm[key] = data[key] || data.contactId;
            this.baseInfoForm[key] *= 1;
            break;
          case "taskId":
            this.baseInfoForm[key] = this.taskId;
            break;
          case "province":
            this.baseInfoForm[key] = data.provinceId;
            break;
          case "city":
            this.baseInfoForm[key] = data.cityId;
            break;
          case "insCounty":
            this.baseInfoForm[key] = data.insCountyId;
            break;
          case "street":
            this.baseInfoForm[key] = data.streetId;
            break;
          case "corpId":
            this.baseInfoForm[key] = data.customerId || data.corpId;
            break;
          case "corpName":
            this.baseInfoForm[key] = data.customerName || data.corpName;
            break;
          case "contactName":
            this.baseInfoForm[key] = data.contact || data.contactName;
            break;
          case "insAddress":
            this.baseInfoForm[key] = data.address2 || data.insAddress;
            break;
          case "expectTime":
            this.baseInfoForm[key] =
              data.expactTimeStamp || data.expectTimeStamp;
            break;
          case "corpContactName":
            this.baseInfoForm[key] = data.corpContactName;
            break;
          case "corpContactPhone":
            this.baseInfoForm[key] = data.corpContactPhone;
            break;
          case "taskLevelCode":
            this.baseInfoForm[key] = data[key] && data[key] + "";
            break;
          default:
            this.baseInfoForm[key] = data[key];
        }
      }
      this.baseInfoForm.defaultCity =
        this.baseInfoForm.provinceName +
        this.baseInfoForm.cityName +
        this.baseInfoForm.insCountyName +
        this.baseInfoForm.streetName;
      this.getInitData();
    },
    async getInitData() {
      try {
        this.loading = true;
        let promiseList;
        console.log(this.taskSource);
        if (this.taskSource == 3 || this.taskSource == 5) {
          promiseList = [
            this.loadAddressData(this.baseInfoForm.defaultCity, true)
          ];
        } else {
          promiseList = [
            this.loadAddressData(this.baseInfoForm.defaultCity, true),
            this.getContactList()
          ];
        }
        await Promise.all(promiseList);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取枚举信息
    async initData() {
      try {
        let enumKey = ["newTaskLevel"];
        //获取枚举数据
        let promiseList = [findDownList(enumKey)];
        let [findDownListRes] = await Promise.all(promiseList);
        enumKey.map(item => {
          this[`${item}Enum`] = this.getFreezeResponse(findDownListRes, {
            path: `data.${item}`
          });
        });
      } catch (error) {
        printError(error);
      }
    },
    //添加联系人
    addConcat() {
      if (!this.baseInfoForm.corpId) {
        this.$message.warning("请先选择客户名称");
        return;
      }
      this.addContactVisible = true;
    },
    //获取联系人下拉框
    async getContactList() {
      try {
        let res = await getContactList({ id: this.baseInfoForm.corpId });
        //客户名称下拉框
        this.contactList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.baseInfoForm.province = val.provinceCode;
      this.baseInfoForm.provinceName = val.provinceName;
      this.baseInfoForm.city = val.cityCode;
      this.baseInfoForm.cityName = val.cityName;
      this.baseInfoForm.insCounty = val.countyCode;
      this.baseInfoForm.insCountyName = val.countyName;
      this.baseInfoForm.streetId = val.streetCode;
      this.baseInfoForm.streetName = val.streetName;
      this.baseInfoForm.defaultCity = val.fullName;
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.defaultCity = "";
        this.baseInfoForm.province = "";
        this.baseInfoForm.provinceName = "";
        this.baseInfoForm.city = "";
        this.baseInfoForm.cityName = "";
        this.baseInfoForm.insCounty = "";
        this.baseInfoForm.insCountyName = "";
        this.baseInfoForm.streetId = "";
        this.baseInfoForm.streetName = "";
        this.loadAddressData(val);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "", needHandleAddressIdToBaseForm) {
      try {
        let res = await getAddressData({
          addressName: val
        });
        //获取地址下拉框
        this.addressList = this.getFreezeResponse(res, {
          path: "data"
        });
        let { province, city, insCounty } = this.baseInfoForm;
        needHandleAddressIdToBaseForm =
          needHandleAddressIdToBaseForm && (!province || !city || !insCounty);
        if (needHandleAddressIdToBaseForm) {
          let targetAddress = this.addressList.find(
            item => item.fullName === val
          );
          // 自动配置地址ID 匹配不到时置空
          this.handleCitySelect("", targetAddress || {});
        }
      } catch (error) {
        printError(error);
      }
    },
    //清空联系人
    handleClear() {
      this.baseInfoForm.contactPhone = "";
      this.baseInfoForm.contactName = "";
      this.baseInfoForm.provinceName = "";
      this.baseInfoForm.province = "";
      //市
      this.baseInfoForm.cityName = "";
      this.baseInfoForm.city = "";
      //区
      this.baseInfoForm.insCountyName = "";
      this.baseInfoForm.insCounty = "";
      //街道
      this.baseInfoForm.streetName = "";
      this.baseInfoForm.street = "";
      //详细地址
      this.baseInfoForm.insAddress = "";
      this.baseInfoForm.defaultCity = "";
    },
    //选择
    handleContact() {
      let concatInfo = this.contactList.find(item => {
        return item.contactId == this.baseInfoForm.linkmanrpid;
      });
      this.baseInfoForm.contactName = concatInfo.contactName;
      this.baseInfoForm.contactPhone = concatInfo.phone1
        ? concatInfo.phone1
        : "";
      if (concatInfo.addressList.length) {
        //省
        this.baseInfoForm.provinceName = concatInfo.addressList[0].provinceName;
        this.baseInfoForm.province = concatInfo.addressList[0].provinceId;
        //市
        this.baseInfoForm.cityName = concatInfo.addressList[0].cityName;
        this.baseInfoForm.city = concatInfo.addressList[0].cityId;
        //区
        this.baseInfoForm.insCountyName = concatInfo.addressList[0].areaName;
        this.baseInfoForm.insCounty = concatInfo.addressList[0].areaId;
        //街道
        this.baseInfoForm.streetName = concatInfo.addressList[0].streetName;
        this.baseInfoForm.street = concatInfo.addressList[0].streetId;
        //详细地址
        this.baseInfoForm.insAddress = concatInfo.addressList[0].address;
        //默认地址
        this.baseInfoForm.defaultCity =
          concatInfo.addressList[0].provinceName +
          concatInfo.addressList[0].cityName +
          concatInfo.addressList[0].areaName +
          concatInfo.addressList[0].streetName;
      }
    },
    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    },
    handleSubmit() {
      this.$refs.baseInfoForm.validate().then(() => {
        this.updateTaskDetailBasicInfo();
      });
    },
    async updateTaskDetailBasicInfo() {
      try {
        this.loading = true;
        let res = await updateTaskDetailBasicInfo({
          ...this.baseInfoForm,
          street: this.baseInfoForm.streetId
        });
        if (res.code === "OK") {
          this.$message.success("修改成功");
          this.$emit("update");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleCloseAddconcat() {
      this.addContactVisible = false;
    },
    handleClose() {
      this.visible = false;
      this.baseInfoForm = {
        taskLevelCode: "", //任务级别
        corpId: "", //客户ID
        corpName: "", //客户名称
        taskId: "", //工单ID
        linkmanrpid: "", //联系人ID
        contactName: "", //联系人名称
        contactPhone: "", //联系人电话
        province: "", // 省ID
        provinceName: "",
        city: "", // 市ID
        cityName: "",
        insCounty: "", // 区ID
        insCountyName: "",
        street: "", // 街道ID
        streetName: "",
        defaultCity: "", //组合省市区街道
        insAddress: "", // 详细地址
        expectTime: "", // 期望上门时间
        remark: "", // 备注
        corpContactName: "",
        corpContactPhone: ""
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.select-content {
  display: flex;
  flex-wrap: nowrap;
  .el-select {
    width: 200px;
    /deep/ .el-input {
      width: 200px;
    }
  }
}
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .e6-vr-select {
      width: 300px;
    }
    .el-input {
      width: 300px;
    }
    .el-textarea {
      width: 300px;
    }
  }
}
</style>
