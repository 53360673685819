<!--
 * @Description: 费用明细弹框
 * @Author: ChenXueLin
 * @Date: 2021-08-26 15:08:54
 * @LastEditTime: 2022-07-01 09:25:14
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="费用明细详情"
    :visible="feeDetailVisible"
    width="800px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleCloseScene"
    custom-class="splie-order-dialog"
    v-loading="dialogLoading"
  >
    <div class="table-box">
      <div class="install-info">
        <!-- <div class="info">
          <span>场景名称</span>
          <span>粤B9V377</span>
        </div> -->
        <div class="info">
          <span>场景类型</span>
          <span>{{ curRow.sceneName }}</span>
        </div>
      </div>
      <el-table
        border
        :data="feeDetailData"
        highlight-current-row
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "feeDetailDialog",
  components: {},
  data() {
    return {
      columnData: [
        {
          fieldName: "fixCatName",
          display: true,
          fieldLabel: "收费项目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixTypeName",
          display: true,
          fieldLabel: "收费类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyName",
          display: true,
          fieldLabel: "币种",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "paidIn",
          display: true,
          fieldLabel: "收费金额",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ]
    };
  },
  props: [
    "feeDetailVisible",
    "feeDetailData",
    "feeDetailTotalAmount",
    "curRow",
    "dialogLoading"
  ],
  mixins: [base],
  computed: {},
  watch: {},
  created() {},
  methods: {
    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        if (index === 3) {
          sums[index] = this.feeDetailTotalAmount;
        }
      });
      return sums;
    },
    //关闭弹框
    handleCloseScene() {
      this.$emit("handleCloseScene");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.splie-order-dialog {
  .table-box {
    padding: 15px;
    box-sizing: border-box;
    .install-info {
      display: flex;
      margin-bottom: 15px;
      .info {
        margin-right: 40px;
        > span {
          &:nth-child(1) {
            color: #a9b5cd;
            margin-left: 5px;
          }
          &:nth-child(2) {
            color: #535355;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
