var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"tree-table"},[_c('tr',[_c('td',{class:{
        parentLevel:
          Array.isArray(_vm.treeData.children) && _vm.treeData.children.length,
        extend:
          Array.isArray(_vm.treeData.children) &&
          _vm.treeData.children.length &&
          _vm.treeData.extend
      },attrs:{"colspan":Array.isArray(_vm.treeData.children) ? _vm.treeData.children.length * 2 : 1}},[_c('div',{class:{ node: true, hasMate: _vm.treeData.mate }},[_c('div',{staticClass:"person",class:Array.isArray(_vm.treeData.class) ? _vm.treeData.class : []},[(_vm.treeData.workNo)?_c('div',{staticClass:"orderNo"},[_c('span',[_vm._v(_vm._s(_vm.treeData.workType)+"：")]),_vm._v(" "),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toDetail(_vm.treeData)}}},[_vm._v(_vm._s(_vm.treeData.workNo))])],1):_vm._e(),_vm._v(" "),(_vm.treeData.createBy)?_c('div',{staticClass:"createby"},[_c('span',[_vm._v("创建人")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.treeData.createBy))])]):_vm._e(),_vm._v(" "),(_vm.treeData.createdTimeStr)?_c('div',{staticClass:"createby"},[_c('span',[_vm._v("创建时间")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.treeData.createdTimeStr))])]):_vm._e(),_vm._v(" "),(_vm.treeData.status)?_c('div',{class:[
              { redBtn: _vm.treeData.statusCode == 1 },
              { blueBtn: _vm.treeData.statusCode == 2 },
              { greenBtn: _vm.treeData.statusCode == 3 },
              { yellowBtn: _vm.treeData.statusCode == 4 },
              'status'
            ]},[_vm._v("\n            "+_vm._s(_vm.treeData.status)+_vm._s(_vm.treeData.numStr)+"\n          ")]):_vm._e()]),_vm._v(" "),(Array.isArray(_vm.treeData.mate) && _vm.treeData.mate.length)?_vm._l((_vm.treeData.mate),function(mate,mateIndex){return _c('div',{key:_vm.treeData.name + mateIndex,staticClass:"person",class:Array.isArray(mate.class) ? mate.class : []},[(_vm.treeData.createBy)?_c('div',{staticClass:"createby"},[_c('span',[_vm._v("创建人")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.treeData.createBy))])]):_vm._e(),_vm._v(" "),(_vm.treeData.createdTimeStr)?_c('div',{staticClass:"createby"},[_c('span',[_vm._v("创建时间")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.treeData.createdTimeStr))])]):_vm._e(),_vm._v(" "),(_vm.treeData.status)?_c('div',{class:[
                { redBtn: _vm.treeData.statusCode == 1 },
                { blueBtn: _vm.treeData.statusCode == 2 },
                { greenBtn: _vm.treeData.statusCode == 3 },
                { yellowBtn: _vm.treeData.statusCode == 4 },
                'status'
              ]},[_vm._v("\n              "+_vm._s(_vm.treeData.status)+_vm._s(_vm.treeData.numStr)+"\n            ")]):_vm._e()])}):_vm._e()],2),_vm._v(" "),(Array.isArray(_vm.treeData.children) && _vm.treeData.children.length)?_c('div',{staticClass:"extend_handle"}):_vm._e()])]),_vm._v(" "),(
      Array.isArray(_vm.treeData.children) &&
        _vm.treeData.children.length &&
        _vm.treeData.extend
    )?_c('tr',_vm._l((_vm.treeData.children),function(children,index){return _c('td',{key:index,staticClass:"childLevel",attrs:{"colspan":"2"}},[_c('TreeChart',{attrs:{"json":children}})],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }