<!--
 * @Description: 设置跟进时间
 * @Author: ChenXueLin
 * @Date: 2021-08-30 11:36:51
 * @LastEditTime: 2022-07-15 16:38:16
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="设置跟进时间"
    :visible="setTimeDialog"
    width="500px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    v-loading="loading"
    custom-class="time-dialog"
  >
    <el-form
      label-width="90px"
      ref="setTimeForm"
      :model="setTimeForm"
      :rules="setTimeFormRulers"
    >
      <el-form-item label="跟进时间" prop="followDate">
        <el-date-picker
          v-model="setTimeForm.followDate"
          type="datetime"
          placeholder="选择日期"
          value-format="timestamp"
          popper-class="special"
          :picker-options="pickerOptions1"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { createFollowTime } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "setTime",
  components: {},
  data() {
    return {
      loading: false,
      setTimeForm: {
        followDate: ""
      },
      setTimeFormRulers: {
        followDate: [
          {
            required: true,
            message: "请选择跟进时间",
            trigger: ["blur", "change"]
          }
        ]
      },
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      }
    };
  },
  props: ["setTimeDialog", "taskId"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    //点击确定新增
    handleSubmit() {
      this.$refs.setTimeForm.validate(valid => {
        if (valid) {
          this.createFollowTimeReq();
        }
      });
    },
    //添加跟进时间请求
    async createFollowTimeReq() {
      try {
        this.loading = true;
        let res = await createFollowTime({
          followDateList: [this.setTimeForm.followDate],
          taskId: this.taskId
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("getTaskBasicInfo");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.setTimeForm = {
        followDate: ""
      };
      this.$refs.setTimeForm.resetFields();
      this.$emit("handleClose", "setTimeDialog");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.time-dialog {
  .el-dialog__body {
    padding-top: 20px;
  }
}
</style>
