<template>
  <table class="tree-table">
    <tr>
      <td
        :colspan="
          Array.isArray(treeData.children) ? treeData.children.length * 2 : 1
        "
        :class="{
          parentLevel:
            Array.isArray(treeData.children) && treeData.children.length,
          extend:
            Array.isArray(treeData.children) &&
            treeData.children.length &&
            treeData.extend
        }"
      >
        <div :class="{ node: true, hasMate: treeData.mate }">
          <div
            class="person"
            :class="Array.isArray(treeData.class) ? treeData.class : []"
          >
            <div class="orderNo" v-if="treeData.workNo">
              <span>{{ treeData.workType }}：</span>
              <el-button type="text" @click="toDetail(treeData)">{{
                treeData.workNo
              }}</el-button>
            </div>

            <div class="createby" v-if="treeData.createBy">
              <span>创建人</span>
              <span>{{ treeData.createBy }}</span>
            </div>
            <div class="createby" v-if="treeData.createdTimeStr">
              <span>创建时间</span>
              <span>{{ treeData.createdTimeStr }}</span>
            </div>
            <div
              v-if="treeData.status"
              :class="[
                { redBtn: treeData.statusCode == 1 },
                { blueBtn: treeData.statusCode == 2 },
                { greenBtn: treeData.statusCode == 3 },
                { yellowBtn: treeData.statusCode == 4 },
                'status'
              ]"
            >
              {{ treeData.status }}{{ treeData.numStr }}
            </div>
          </div>
          <template v-if="Array.isArray(treeData.mate) && treeData.mate.length">
            <div
              class="person"
              v-for="(mate, mateIndex) in treeData.mate"
              :key="treeData.name + mateIndex"
              :class="Array.isArray(mate.class) ? mate.class : []"
            >
              <div class="createby" v-if="treeData.createBy">
                <span>创建人</span>
                <span>{{ treeData.createBy }}</span>
              </div>
              <div class="createby" v-if="treeData.createdTimeStr">
                <span>创建时间</span>
                <span>{{ treeData.createdTimeStr }}</span>
              </div>
              <div
                v-if="treeData.status"
                :class="[
                  { redBtn: treeData.statusCode == 1 },
                  { blueBtn: treeData.statusCode == 2 },
                  { greenBtn: treeData.statusCode == 3 },
                  { yellowBtn: treeData.statusCode == 4 },
                  'status'
                ]"
              >
                {{ treeData.status }}{{ treeData.numStr }}
              </div>
            </div>
          </template>
        </div>
        <div
          class="extend_handle"
          v-if="Array.isArray(treeData.children) && treeData.children.length"
        ></div>
      </td>
    </tr>
    <tr
      v-if="
        Array.isArray(treeData.children) &&
          treeData.children.length &&
          treeData.extend
      "
    >
      <td
        v-for="(children, index) in treeData.children"
        :key="index"
        colspan="2"
        class="childLevel"
      >
        <TreeChart :json="children" />
        <!--  @click-node="$emit('click-node', $event)" -->
      </td>
    </tr>
  </table>
</template>

<script>
import base from "@/mixins/base";
import eventBus from "@/bus";
export default {
  name: "TreeChart",
  props: ["json"],
  data() {
    return {
      treeData: {}
    };
  },
  watch: {
    json: {
      handler: function(Props) {
        let extendKey = function(jsonData) {
          jsonData.extend =
            jsonData.extend === void 0 ? true : !!jsonData.extend;
          if (Array.isArray(jsonData.children)) {
            jsonData.children.forEach(c => {
              extendKey(c);
            });
          }
          return jsonData;
        };
        if (Props) {
          this.treeData = extendKey(Props);
        }
      },
      immediate: true
    }
  },
  mixins: [base],
  methods: {
    //点击单据号跳转
    toDetail(treeData) {
      if (treeData.workTypeCode == 1) {
        if (this.$route.name == "serviceListManage/serviceDetail") {
          eventBus.$emit("reload", treeData.taskId);
        } else {
          //服务单
          this.routerPush({
            name: "serviceListManage/serviceDetail",
            params: {
              refresh: true,
              serviceId: treeData.taskId
            }
          });
        }
      } else if (treeData.workTypeCode == 3) {
        //任务单
        if (this.$route.name == "taskListManage/detail") {
          eventBus.$emit("reload", treeData.taskId);
        } else {
          this.routerPush({
            name: "taskListManage/detail",
            params: {
              refresh: true,
              taskId: treeData.taskId
            }
          });
        }
      }
    },
    toggleExtend: function(treeData) {
      treeData.extend = !treeData.extend;
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped lang="scss">
table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}
td {
  position: relative;
  vertical-align: top;
  padding: 0 20px 50px 20px;
  text-align: center;
}
.extend_handle {
  position: absolute;
  left: 50%;
  bottom: 30px;
  width: 10px;
  height: 10px;
  padding: 10px;
  transform: translate3d(-15px, 0, 0);
  cursor: pointer;
}
// .extend_handle:before {
//   content: "";
//   display: block;
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   border: 2px solid;
//   border-color: #ccc #ccc transparent transparent;
//   transform: rotateZ(135deg);
//   transform-origin: 50% 50% 0;
//   transition: transform ease 300ms;
// }
.extend_handle:hover:before {
  border-color: #333 #333 transparent transparent;
}
/* .extend .extend_handle:before{transform: rotateZ(-45deg);} */
.extend::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 15px;
  height: 15px;
  border-left: 2px solid #ccc;
  transform: translate3d(-1px, 0, 0);
}
.childLevel::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  height: 15px;
  border-left: 2px solid #ccc;
  transform: translate3d(-1px, 0, 0);
}
.childLevel::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -17px;
  border-top: 2px solid #ccc;
}
.childLevel:first-child:before,
.childLevel:last-child:before {
  display: none;
}
.childLevel:first-child:after {
  left: 50%;
  height: 30px;
  border: 2px solid;
  border-color: #ccc transparent transparent #ccc;
  border-radius: 6px 0 0 0;
  transform: translate3d(1px, 0, 0);
}
.childLevel:last-child:after {
  right: 50%;
  height: 30px;
  border: 2px solid;
  border-color: #ccc #ccc transparent transparent;
  border-radius: 0 6px 0 0;
  transform: translate3d(-1px, 0, 0);
}
.childLevel:first-child.childLevel:last-child::after {
  left: auto;
  border-radius: 0;
  border-color: transparent #ccc transparent transparent;
  transform: translate3d(1px, 0, 0);
}
.node {
  position: relative;
  display: inline-block;
  margin: 0 1em;
  box-sizing: border-box;
  text-align: center;
}
.node:hover {
  color: #2d8cf0;
  cursor: pointer;
}
.node .person {
  position: relative;
  display: inline-block;
  z-index: 2;
  width: 212px;
  overflow: hidden;
}

.node .person .name {
  height: 2em;
  line-height: 2em;
  overflow: hidden;
  width: 100%;
}
.node.hasMate::after {
  content: "";
  position: absolute;
  left: 2em;
  right: 2em;
  top: 2em;
  border-top: 2px solid #ccc;
  z-index: 1;
}
.node .paeson_name {
  transform: rotate(90deg);
  position: absolute;
  top: 68px;
  right: 39px;
  width: 88px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.landscape {
  transform: translate(-100%, 0) rotate(-90deg);
  transform-origin: 100% 0;
}
.landscape .node {
  text-align: left;
  height: 8em;
  width: 8em;
  right: 18px;
}
.landscape .person {
  position: absolute;
  transform: rotate(90deg);
  height: 4em;
  top: 4em;
  left: 2.5em;
}

.landscape .person .name {
  height: 4em;
  line-height: 4em;
}
.landscape .hasMate {
  position: relative;
}
.landscape .hasMate .person {
  position: absolute;
}
.landscape .hasMate .person:first-child {
  left: auto;
  right: -4em;
}
.landscape .hasMate .person:last-child {
  left: -4em;
  margin-left: 0;
}
.orderNo {
  color: #2b2c2e;
  font-size: 14px;
}
.createby {
  margin-top: 5px;
  & > span {
    &:nth-child(1) {
      color: #b5b8bd;
      font-size: 12px;
    }
    &:nth-child(2) {
      color: #5f6063;
      font-size: 14px;
    }
  }
}
.status {
  width: 70px;
  height: 20px;
  margin-top: 8px;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  background: #67c23a;
  text-align: center;
  line-height: 20px;
}
.node {
  min-width: 212px;
  // height: 110px;
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  border-radius: 10px;
  background: #edf0f5;
  .person {
    position: relative;
    display: inline-block;
    z-index: 2;
    width: 100%;
    overflow: hidden;
    .orderNo {
      color: #2b2c2e;
      font-size: 14px;
    }
    .createby {
      margin-top: 5px;
      > span {
        &:nth-child(1) {
          color: #b5b8bd;
          font-size: 12px;
        }
        &:nth-child(2) {
          color: #5f6063;
          font-size: 14px;
        }
      }
    }
    .status {
      width: 70px;
      padding: 2px 5px;
      height: 20px;
      margin-top: 8px;
      border-radius: 20px;
      color: #fff;
      font-size: 14px;
      background: #67c23a;
      text-align: center;
      line-height: 20px;
    }
    .blueBtn {
      background: #1a96f8;
    }
    .greenBtn {
      background: #4fb85b;
    }
    .redBtn {
      background: #f56c6c;
    }
    .yellowBtn {
      background: #e6a23c;
    }
  }
}
.tree-table {
  margin: 20px auto;
}
</style>
